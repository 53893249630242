const offerSearchForm = document.querySelector('form.offer-search');
let successMsg = null;

if (offerSearchForm) {
  successMsg = document.querySelector('.form-success-msg');

  document.addEventListener('onAfterFormieSubmit', (e) => {
    if (e.detail.success) {
      successMsg.classList.remove('hidden');
      offerSearchForm.classList.add('hidden');
    }
  });
}
