/* eslint-disable */
import jQuery from 'jquery';
import Parsley from 'parsleyjs';
import Sortable from 'sortablejs';

import 'jquery-modal';
import '@chenfengyuan/datepicker';

(function($, Parsley, Sortable) {

    Date.prototype.yyyymmdd = function() {
        let mm = this.getMonth() + 1; // getMonth() is zero-based
        let dd = this.getDate();

        return [
            this.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd,
        ].join('-');
    };

    Parsley.addMessages('de', {
        defaultMessage: 'Die Eingabe scheint nicht korrekt zu sein.',
        type: {
            email: 'Die Eingabe muss eine gültige E-Mail-Adresse sein.',
            url: 'Die Eingabe muss eine gültige URL sein.',
            number: 'Die Eingabe muss eine Zahl sein.',
            integer: 'Die Eingabe muss eine Zahl sein.',
            digits: 'Die Eingabe darf nur Ziffern enthalten.',
            alphanum: 'Die Eingabe muss alphanumerisch sein.',
        },
        notblank: 'Die Eingabe darf nicht leer sein.',
        required: 'Dies ist ein Pflichtfeld.',
        pattern: 'Die Eingabe scheint ungültig zu sein.',
        min: 'Die Eingabe muss größer oder gleich %s sein.',
        max: 'Die Eingabe muss kleiner oder gleich %s sein.',
        range: 'Die Eingabe muss zwischen %s und %s liegen.',
        minlength: 'Mindestens %s Zeichen.',
        maxlength: 'Die Eingabe ist zu lang. Es dürfen höchstens %s Zeichen eingegeben werden.',
        length: 'Die Länge der Eingabe ist ungültig. Es müssen zwischen %s und %s Zeichen eingegeben werden.',
        mincheck: 'Wählen Sie mindestens %s Angaben aus.',
        maxcheck: 'Wählen Sie maximal %s Angaben aus.',
        check: 'Wählen Sie zwischen %s und %s Angaben.',
        equalto: 'Dieses Feld muss dem anderen entsprechen.',
    });

    Parsley.setLocale('de');

    $.fn.datepicker.languages['de-DE'] = {
        format: 'dd.mm.yyyy',
        days: [
            'Sonntag',
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag'
        ],
        daysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        weekStart: 1,
        months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ],
        monthsShort: [
            'Jan',
            'Feb',
            'Mär',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dez'
        ],
    };

    $(document).ready(function() {

        let $contactForm = $('#contact-form');
        let $cFormSpinner = $contactForm.find('.spinner-wrap');
        if ($contactForm.length > 0) {
            $contactForm.parsley()
                .on('form:submit', function() {
                    $cFormSpinner.addClass('spinning');
                });
        }

        let $regForm = $('#reg-form');
        let $errorMsg = $('.registration.error-msg');
        let $errorContainer = $errorMsg.find('.error-container');

        $regForm.on('submit', function(ev) {
            ev.preventDefault();
            let $this = $(this);
            let $spinner = $this.find('.spinner-wrap');

            $errorMsg.addClass('hidden');
            $spinner.addClass('spinning');
            if ($this.parsley().isValid()) {
                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $this.serialize(),
                    success: resp => {
                        $spinner.removeClass('spinning');
                        $('.success-msg').removeClass('hidden');
                        $this.remove();
                    },
                    error: (errors) => {
                        $spinner.removeClass('spinning');
                        displayRegErrors(errors)
                        $errorMsg.removeClass('hidden');
                    }
                });
            }
        });

        let displayRegErrors = errors => {
            const errorObj = errors.responseJSON;
            let err = `<p>${errorObj.message}</p>`;
            if (errorObj.errors.hasOwnProperty('email')) {
                err += `<p>${errorObj.errors.email[0]}</p>`;
            }
            if (errorObj.errors.hasOwnProperty('username')) {
                err += `<p>${errorObj.errors.username[0]}</p>`;
            }
            $errorContainer.append(err);
        }

        let $profileSpinner = $('.profile-container .spinner-wrap');
        let $profileForm = $('#profile-form');
        $profileForm.on('submit', function() {
            $profileSpinner.addClass('spinning');
        });

        let $updatePWForm = $('#update-password');
        $updatePWForm.on('submit', function() {
            $profileSpinner.addClass('spinning');
        })

        let $deleteProfileForm = $('#delete-profile');
        $deleteProfileForm.on('submit', function() {
            $profileSpinner.addClass('spinning');
        });

        let $offerSearchForm = $('#offer-search-form');
        $offerSearchForm.on('submit', function(ev) {
            ev.preventDefault();
            let $this = $(this);
            let $spinner = $this.find('.spinner-wrap');
            $spinner.addClass('spinning');
            if ($this.parsley().isValid()) {
                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $this.serialize(),
                    success: resp => {
                        $spinner.removeClass('spinning');
                        if (resp.success) {
                            $('.success-msg').removeClass('hidden');
                            $this.remove();
                        } else {
                            console.log('ERROR', resp);
                        }
                    },
                });
            }
        });

        let $loginForm = $('#login-form');
        let redirectPath = $loginForm.data('login-redirect');
        let $loginSpinner = $loginForm.prev('.spinner-wrap');
        $loginForm.on('submit', function(ev) {
            ev.preventDefault();
            let $this = $(this);
            $loginSpinner.addClass('spinning');
            let $errMsg = $this.find('p.error');
            if ($errMsg) {
                $errMsg.remove();
            }
            if ($this.parsley().isValid()) {
                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $this.serialize(),
                    success: response => {
                        $loginSpinner.removeClass('spinning');
                        if(redirectPath == 'reload') {
                            window.location.reload();
                        } else{
                            window.location.replace(redirectPath);
                        }
                    },
                    error: error => {
                        $loginSpinner.removeClass('spinning');
                        $this.prepend(`<p class="error mt-2 mx-3">${error.responseJSON.message}</p>`);
                    }
                });
            }
        });

        let $resetPwForm = $('#pw-reset-form');
        let $resetSpinner = $resetPwForm.prev('.spinner-wrap');
        let $resetSuccessMsg = $resetPwForm.next('.success-msg');
        $resetPwForm.on('submit', function(ev) {
            ev.preventDefault();
            let $this = $(this);
            $resetSpinner.addClass('spinning');
            let $errMsg = $this.find('p.error');
            if ($errMsg) {
                $errMsg.remove();
            }
            if ($this.parsley().isValid()) {
                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $this.serialize(),
                    success: response => {
                        $resetSpinner.removeClass('spinning');
                        $resetSuccessMsg.removeClass('hidden');
                        $resetSuccessMsg.addClass('flex');
                        $this.remove();
                    },
                    error: error => {
                        $resetSpinner.removeClass('spinning');
                        $this.prepend(`<p class="error mt-2 mx-3">${error.responseJSON.message}</p>`);
                    },
                });
            }
        });

        let pickerOpt = {
            format: 'dd.mm.yyyy',
            startDate: Date.now(),
            language: 'de-DE',
        };

        let $fromWhenDate = $('.from-when-container');
        let $fromWhenPicker = $fromWhenDate.find('.display-date');
        let $fromWhenInput = $fromWhenDate.find('input');
        $fromWhenPicker.on({
            'pick.datepicker': function(e) {
                $fromWhenInput.val(e.date.yyyymmdd());
            },
        }).datepicker(pickerOpt);

        let $byWhenDate = $('.by-when-container');
        let $byWhenPicker = $byWhenDate.find('.by-when-picker');
        let $byWhenInput = $byWhenDate.find('input');
        $byWhenPicker.on({
            'pick.datepicker': function(e) {
                $byWhenInput.val(e.date.yyyymmdd());
            },
        }).datepicker(pickerOpt);
        $('.reset-date-by').on('click', function(e) {
            $(this).prev().text('');
            $byWhenInput.val('');
            $byWhenPicker.datepicker('destroy');
            $byWhenPicker.datepicker(pickerOpt);
        });

        const $offerForm = $('#offer-form');
        const $totalSize = $offerForm.find('.display-total-size');
        const $priceContainer = $offerForm.find('.price-container');
        const $fileContainer = $('.file-container');
        let fileInputHtml = $fileContainer.html();

        $offerForm.on('change', '#offerPriceCalc', function() {
            let val = $(this).val();
            let $priceInput = $priceContainer.find('#offerPrice');
            console.log(val);
            if (val === 'fixed' || val === 'negotiable') {
                $priceContainer.fadeIn();
                $priceInput.prop('required', true)
            } else {
                $priceContainer.fadeOut().removeClass('input-focus');
                $priceInput.prop('required', false).val('');
            }

        });

        let fileCounter = 1;
        let fileSizes = 0;

        function appendClone() {
            let copy = fileInputHtml.replace(/new1/g, `new${fileCounter + 1}`);
            let $copy = $(copy);
            $copy.find('label').attr('for', `file-${fileCounter}`);
            $copy.find('input[type=file]').attr('id', `file-${fileCounter}`);
            fileCounter += 1;
            $fileContainer.append($copy);
        }

        function displayTotalSize() {
            $totalSize.text(parseFloat(fileSizes / 1000000).toFixed(1));
        }

        $offerForm.on('change', 'input[type=file]', function(ev) {

            let $parent = $(this).parent('.select-img-container');
            let files = !!this.files ? this.files : [];
            if (!files.length || !window.FileReader) {
                return;
            } // no file selected, or no FileReader support

            if (/^image/.test(files[0].type)) {
                let reader = new FileReader(); // instance of the FileReader

                fileSizes += files[0].size;
                reader.readAsDataURL(files[0]);
                reader.onloadend = function() { // set image data as background of div

                    if ($parent.attr('data-size')) {
                        fileSizes -= $parent.data('size');
                    }

                    if (fileSizes > 5000000) {
                        alert('Die 5MB für den Upload sind überschritten');
                        fileSizes -= files[0].size;
                        return false;
                    }

                    if (!$parent.hasClass('selected')) {
                        appendClone();
                    }

                    $parent.addClass('selected');
                    $parent.attr('data-size', files[0].size);
                    $parent.find('.file-thumb')
                        .css('background-image', `url(${this.result})`)
                        .addClass('w-1/3 mr-2');
                    $parent.find('.remove-selected-img')
                        .removeClass('hidden')
                        .addClass('flex');
                    $parent.find('label')
                        .text('Bild austauschen');

                    displayTotalSize();
                };

            }

        });

        $offerForm.on('click', '.remove-selected-img', function(ev) {
            let $this = $(this);
            fileSizes -= $this.closest('.select-img-container').data('size');
            displayTotalSize();
            $this.closest('.file-inner').remove();
        });

        if ($offerForm.length > 0) {
            $offerForm.parsley()
                .on('field:error', function(fieldInstance) {
                    let fieldName = fieldInstance.$element.attr('name');
                    let $field = $('input[name="' + fieldName + '"]');
                    let $fieldWrapper = $field.parents('.content-item');
                    let $errorContainer = $fieldWrapper.find('.error-container');
                    if ($fieldWrapper.hasClass('grouped') && $errorContainer.length > 0) {
                        $fieldWrapper.append($fieldWrapper.find('.parsley-errors-list'));
                    }
                });
        }

        $offerForm.on('submit', function(ev) {
            ev.preventDefault();
            let $this = $(this);
            $this.find('.spinner-wrap').addClass('spinning');
            $this.find('.file-container .file-inner').last().remove();
            $this.unbind('submit').submit();
        });

        let $usedImagesContainer = $('.used-images-container');
        $('.img-desc-modify').on($.modal.OPEN, function(ev, modal) {
            let index = modal.$elm.attr('id').split('-').pop();
            let $txtTarget = $usedImagesContainer.find(`.textarea-hidden-${index}`);
            let $text = modal.$elm.find('textarea');
            $text.on('change', function(ev) {
                $txtTarget.text(ev.target.value);
            });
        });

        if ($usedImagesContainer.length > 0) {
            Sortable.create($usedImagesContainer[0], {
                handle: '.move-handle',
                animation: 150,
            })
        }

        $usedImagesContainer.on('click', '.remove-handle', function() {
            $(this).closest('.file-inner').remove();
        });

        let $archiveForm = $('form#archive-form');
        let $archiveLinks = $('a.archive-link');
        let $archiveModal = $('#archive-modal');
        let $archiveRemark = $archiveModal.find('.archive-remark');
        $archiveLinks.on('click', function(e) {
            e.preventDefault();
            let $this = $(this);
            let title = $this.attr('data-title');
            let secId = $this.attr('data-sectionId');
            let entryId = $this.attr('data-entryId');
            let remarkTxt = '';
            $archiveForm.find('input[name=sectionId]').val(secId);
            $archiveForm.find('input[name=entryId]').val(entryId);
            if(secId === '3') {
                remarkTxt = `Angebot <strong>${title}</strong> archivieren`
            } else {
                remarkTxt = `Suchauftrag <strong>${title}</strong> archivieren`
            }
            $archiveRemark.html(remarkTxt);
            $archiveModal.modal();
        });

        $archiveModal.on('click', '.accept-btn', function() {
            console.log($archiveModal.find('.spinner-wrap'));
            $archiveModal.find('.spinner-wrap').addClass('spinning');
            $archiveForm.submit();
        });

    });

})(jQuery, Parsley, Sortable);
