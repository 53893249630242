import jQuery from 'jquery';

import 'tooltipster';
import 'slick-carousel';
import 'lightbox2';

(function ($) {
  $(document).ready(function () {
    $('.tooltip').tooltipster({
	    theme: 'tooltipster-shadow',
	    arrow: false,
		  maxWidth: 280,
	  });

    $('.carousel').slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      infinite: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    const $doc = $(this);
    const $body = $('body');
    const $pageContainer = $('#page-container');
    const $header = $pageContainer.find('header');

    $doc.on('scroll', () => {
      $body.removeClass('open-mobile-nav');
      if ($doc.scrollTop() > 0) {
        $pageContainer.addClass('navbar-fixed');
      } else {
        $pageContainer.removeClass('navbar-fixed');
      }
    });

    const $searchForm = $('.search-form');
    const $searchInput = $searchForm.find('input');
    const $openSearch = $searchForm.find('.icon-search');
    const $closeSearch = $searchForm.find('.icon-cancel');

    $openSearch.on('click', () => {
      $header.addClass('form-visible');
      $searchInput.focus();
    });

    $closeSearch.on('click', () => {
      $header.removeClass('form-visible');
      $searchInput.val('');
    });

    const $hamburgerNav = $('header .hamburger-nav');
    $hamburgerNav.on('click', () => {
      $body.toggleClass('open-mobile-nav');
    });

    const $sidebarLogin = $('#side-nav .login-modal');
    $sidebarLogin.on('click', () => {
      $body.removeClass('open-mobile-nav');
    });

    const $slideOnHover = $('header nav .user-nav');
    $slideOnHover.on('mouseover', () => {
      $header.addClass('subnav-visible');
    });

    $slideOnHover.on('mouseout', () => {
      setTimeout(() => {
        $header.removeClass('subnav-visible');
      }, 200);
    });

    const $inputs = $('.form-input');
    $inputs.each((i, elem) => {
      const $elem = $(elem);
      if ($elem.val().length) {
        $elem.parent().addClass('input-focus');
      }
    });
    $inputs.on('focus', function () {
      $(this).parent().addClass('input-focus');
    });

    $inputs.on('blur', function () {
      const $this = $(this);
      if ($this.val().length > 0) {
        return;
      }
      $this.parent().removeClass('input-focus');
    });

    $('form.use-csfr').prepend(window.CSFR_INPUT);
    const $toReplaceCsrf = $('form.replace-csrf input[name=CRAFT_CSRF_TOKEN]');
    $toReplaceCsrf.replaceWith(window.CSFR_INPUT);

    let resizeTimer;
    $(window).on('resize', () => {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {
        $body.removeClass('open-mobile-nav');
        $header.removeClass('form-visible');
      }, 200);
    });
  });
}(jQuery));
